import { makeTabbable } from '../utilities/functions';

/**
 * 'Select' style menu for navigating to other seties
 * @author Phil Steer
 * @package LoveProductions
 */
export default class OtherSeries {
    constructor(toggle, list) {
        if (!toggle || !list) {
            return;
        }

        this.toggle = toggle;
        this.list = list;
        this.menuElems = this.list.querySelectorAll('a, button, input, select, textarea');
        this.menuElems = Array.prototype.slice.call(this.menuElems);

        this.toggle.addEventListener('click', () => {
            if (this.toggle.classList.contains('is_active')) {
                this.closeMenu();
            } else {
                this.openMenu();
            }
        });

        this.setHeight();
        makeTabbable(false, this.menuElems);
    }

    closeMenu() {
        this.toggle.classList.remove('is_active');
        this.toggle.setAttribute('aria-pressed', false);
        this.list.classList.remove('is_active');
        this.list.setAttribute('aria-hidden', true);
        this.list.style.height = 0;
        makeTabbable(false, this.menuElems);
    }

    openMenu() {
        const listHeight = `${this.list.getAttribute('data-height')}px`;

        this.toggle.classList.add('is_active');
        this.toggle.setAttribute('aria-pressed', true);
        this.list.classList.add('is_active');
        this.list.setAttribute('aria-hidden', false);
        this.list.style.height = listHeight;

        makeTabbable(this.menuElems, false);
    }

    setHeight() {
        let items = this.list.querySelectorAll('.item');
        items = Array.prototype.slice.call(items);

        const numItems = items.length;

        const itemElem = this.list.querySelector('.item .link');
        const styles = window.getComputedStyle(itemElem);
        const margin = parseFloat(styles.marginBottom);
        const borderSize = getComputedStyle(itemElem, null).getPropertyValue('border-bottom-width');
        const itemHeight = parseInt(itemElem.clientHeight, 10) + parseInt(margin, 10) + parseInt(borderSize, 10);
        const listHeight = itemHeight * numItems;

        this.list.setAttribute('data-height', listHeight);
        this.list.style.height = 0;
    }
}
