import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

/**
 * FullPage.js initialisation/setup
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

const FullPageInit = () => {
    const checkHome = document.getElementById('mainHome');

    if (checkHome === null) {
        if (typeof fullpage_api !== 'undefined') {
            // Destroy the fullpage scroller if we aren't on the homepage & it exists
            fullpage_api.destroy('all');
        }
        return;
    }

    // const subtitles = [...document.querySelectorAll('.home-block .subtitle__container')];
    // const videos = [...document.querySelectorAll('.home-block .video video')];

    const sections = Array.from(document.querySelectorAll('.section'));
    const doNotShowNav = sections.length === 1;


    const fullPageInstance = new fullpage('#myFullpage', {
        anchors: ['loveProductions', 'featuredShow', 'shows', 'news', 'contact'],

        // - Key
        licenseKey: 'F3B26A2D-10594FAC-B8495B29-47F8FD42',
        // - Fade Effect
        fadingEffect: true,
        // fadingEffectKey: 'ZWxhc3RpY2JlYW5zdGFsay5jb21fVTFsWm1Ga2FXNW5SV1ptWldOMFNiMw==',
        fadingEffectKey: 'bG92ZXByb2R1Y3Rpb25zLmNvLnVrX3RkM1ptRmthVzVuUldabVpXTjBJZHg=',
        // - Navigation
        navigation: !doNotShowNav,
        navigationPosition: 'right',
        navigationTooltips: ['Home', 'Feature', 'Shows', 'News', 'Contact'],
        sectionsColor: [],
        continuousVertical: false,
        // - Accessibility
        keyboardScrolling: true,
        animateAnchor: true,
        recordHistory: true,
        // - Scrolling
        css3: true,
        scrollingSpeed: 750,
        autoScrolling: true,
        fitToSection: true,
        fitToSectionDelay: 1000,
        scrollBar: true,
        easing: 'easeInOutCubic',
        easingcss3: 'ease',
        // - Design
        verticalCentered: true,
        paddingTop: '105px',
        normalScrollElements: false,

        onLeave(origin, destination, direction) {
            const leaveSection = this;
            if (origin.isFirst) {
                // origin.item.querySelector('.home-lead').style.top = '0';
            }
        },

        afterLoad(origin, destination, direction) {
            const loadedSection = this;
            if (!origin) {
                return;
            }

            if (origin.isFirst) {
                origin.item.querySelector('.home-lead').style.top = '50%';
                document.getElementById('myFullpage').style.overflow = 'hidden';
                // subtitles.forEach(subtitle => {
                //     subtitle.style.opacity = 1;
                // });
                if (fullPageInstance) {
                    fullPageInstance.fadingEffect.turnOff();
                }
            }

            if (destination.isFirst) {
                document.getElementById('myFullpage').style.overflow = 'visible';
                // subtitles.forEach(subtitle => {
                //     subtitle.style.opacity = 0;
                // });
                if (fullPageInstance) {
                    fullPageInstance.fadingEffect.turnOn();
                }
            }

            if (destination.isLast) {
                document.querySelector('.site-foot').style.zIndex = 99;
                document.querySelector('.site-foot').style.opacity = 1;
                document.querySelector('.site-foot').style.transform = 'translateY(-100%)';
            } else {
                document.querySelector('.site-foot').style.zIndex = 0;
                document.querySelector('.site-foot').style.opacity = 0;
                document.querySelector('.site-foot').style.transform = 'translateY(0px)';
            }

            if (doNotShowNav) {
                document.querySelector('.site-foot').style.zIndex = 99;
                document.querySelector('.site-foot').style.opacity = 1;
                document.querySelector('.site-foot').style.transform = 'translateY(0px)';
            }

            const videoEl = destination.item.querySelector('.video video');
            if (videoEl) {
                videoEl.classList.add('playing');
                videoEl.play();
            }
        },
    });

    if (doNotShowNav) {
        if (typeof fullpage_api !== 'undefined') {
            // Destroy the fullpage scroller if we aren't on the homepage & it exists
            fullpage_api.destroy('all');
        }

        const homeHero = document.getElementById('homeHero');
        if (homeHero) {
            homeHero.style.paddingTop = 0;
            homeHero.style.marginTop = '-80px';
        }

        const animationPausePlay = document.getElementById('pauseAnimationLoop');
        if (animationPausePlay) {
            animationPausePlay.classList.add('no-nav');
        }

        return;
    }
};

export default FullPageInit;
