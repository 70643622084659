/**
 * Home videos - play on mouseenter / pause on mouseleave
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

export const HomeVideoPlayer = () => {
    const homeVideos = [...document.querySelectorAll('.home-block')];
    if (homeVideos.length === 0) {
        return;
    }
    const tap = ('ontouchstart' in document.documentElement);
    if (tap) {
        return;
    }

    homeVideos.forEach(videoContainer => {
        const videoSrc = videoContainer.querySelector('video');
        if (!videoSrc) {
            return;
        }

        // CHANGE - Client requests videos always take priority over images on desktop, so will need to start playing by default

        // videoContainer.addEventListener('mouseenter', () => {
        //     videoSrc.play();
        //     videoSrc.classList.add('playing');
        // }, false);
        // videoContainer.addEventListener('mouseleave', () => {
        //     videoSrc.pause();
        //     videoSrc.classList.remove('playing');
        // }, false);

        videoSrc.classList.add('playing');
        videoSrc.play();
    });
};
