import Player from '@vimeo/player';

class SizzleLoader {
    constructor(elem) {
        if (!elem) return;
        this.elem = elem;
        // this.handleEnterButton = this.handleEnterButton.bind(this);
        this.sizzleInputForm = this.elem.querySelector('#sizzleInputForm');
        this.enterButton = this.elem.querySelector('#sizzleEnter');
        this.sizzleInput = this.elem.querySelector('#sizzleInput');
        this.videoContainer = this.elem.querySelector('#sizzleContainer');

        this.vimeoID = null;
        this.correctAuthorURL = 'https://vimeo.com/joipolloi';

        this.init();
    }

    init() {
        this.sizzleInputForm.addEventListener('submit', e => {
            e.preventDefault();
            this.handleEnterButton(e);
        });
    }

    handleEnterButton(e) {
        // check if the field is empty
        const id = this.sizzleInput.value;
        if (id === '') return;
        // this.vimeoID = id;
        this.loadVimeoVideo(id);
        // get the ID from the field
        // console.log('SizzleLoader: handleEnterButton: e: ', e);
        // console.log('SizzleLoader: handleEnterButton: this: ', this);
    }

    loadVimeoVideo(videoId) {
        // check if it's a valid id


        const videoURL = `https://vimeo.com/${videoId}`;
        const url = `https://vimeo.com/api/oembed.json?url=${videoURL}`;

        fetch(url)
            .then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then(json => {
                this.handleVimeoResponse(json);
            })
            .catch(() => {
                this.handleError();
            });

        // const player = new Player('sizzleContainer', { id: null, width: 640 });
    }

    handleVimeoResponse(json) {
        // console.log('SizzleLoader: handleVimeoResponse: json: ', JSON.stringify(json));
        // console.log('SizzleLoader: handleVimeoResponse: json: ', json.author_url);
        if (json.author_url !== this.correctAuthorURL) {
            this.handleError();
        } else {
            this.embedVideo(json);
        }
        // check if the video is owned by the correct account
    }

    embedVideo(json) {
        // console.log('SizzleLoader: embedVideo: ', json);
        const html = `
            <h2>${json.title}</h2>
            <p>${json.description}</p>
            <div>${json.html}</div>
        `;
        this.videoContainer.innerHTML = html;
        this.sizzleInputForm.style.display = 'none';
    }

    handleError() {
        console.log('SizzleLoader: handleError');
        this.videoContainer.innerHTML = '<h2 class="error">ERROR: This is not a valid code</h2>';
    }
}

export default SizzleLoader;
