/**
 * Play videos on hover for shows archive page list items
 * @author Matt Haynes
 * @package LoveProductions
 */

const showsVideoHover = () => {
    const showList = document.querySelector('.programme-block .list'); //change back to document.getElementById('showList') if required.
    if (!showList) return;

    const showArray = Array.from(showList.querySelectorAll('.show-item'));
    showArray.forEach(show => {
        const video = show.querySelector('video');
        if (!video) return;

        show.addEventListener('mouseenter', () => {
            video.play();
        });

        show.addEventListener('mouseleave', () => {
            video.pause();
        });
    });
};

export default showsVideoHover;
