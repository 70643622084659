/**
 * Animating the header logo; on load, on hover, on interval
 *
 * @author Matt Haynes
 * @package LoveProductions
 */
export default class LogoAnimation {
    constructor(logoUK, logoUSA) {
        if (!logoUK && !logoUSA) {
            return;
        }

        this.logoUK = logoUK;
        this.logoUSA = logoUSA;

        this.init();
    }

    init() {
        let logoToAnimate = '';
        let logoStatic = '';
        let logoAnimated = '';

        if (this.logoUK) {
            logoToAnimate = this.logoUK;
            logoStatic = 'love-logo.png';
            logoAnimated = 'love-effect-01.gif';
        } else if (this.logoUSA) {
            logoToAnimate = this.logoUSA;
            logoStatic = 'love-logo-usa.png';
            logoAnimated = 'love-USA-effect-01.gif';
        }

        const headerLogoAnimate = () => {
            logoToAnimate.style.backgroundImage = `url('/assets/templates/love/img/${logoAnimated}`;
            setTimeout(() => {
                logoToAnimate.style.backgroundImage = `url('/assets/templates/love/img/${logoStatic}`;
            }, 5000);
        };

        setInterval(() => {
            headerLogoAnimate();
        }, 20000);

        logoToAnimate.addEventListener('mouseenter', () => {
            logoToAnimate.style.backgroundImage = `url('/assets/templates/love/img/${logoAnimated}`;
        });

        logoToAnimate.addEventListener('mouseleave', () => {
            headerLogoAnimate();
        });
    }

}
