import { TweenLite } from 'gsap/TweenMax';

export default class TeamBlock {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.elem = elem;
        this.panelContainers = this.elem.querySelectorAll('.team-block__container');
        this.panelToggles = this.elem.querySelectorAll('.team-block__button');
        this.panelContents = this.elem.querySelectorAll('.team-block__member');
        this.panelContainers = Array.prototype.slice.call(this.panelContainers);
        this.panelToggles = Array.prototype.slice.call(this.panelToggles);
        this.panelContents = Array.prototype.slice.call(this.panelContents);

        this.init();
    }

    init() {
        // - Create groups of elements for vertical alignment: start
        const teamMembers = [...this.panelContainers];
        let windowViewWidth = window.innerWidth;
        let groupSize = 4;
        let buttonWidthSelected = `${350 / 1110 * 100}%`;
        let buttonWidthUnselected = `${240 / 1110 * 100}%`;
        
        if (windowViewWidth > 1024) {
            groupSize = 4;
            buttonWidthSelected = `${350 / 1110 * 100}%`;
            buttonWidthUnselected = `${240 / 1110 * 100}%`;
        }
        
        if (windowViewWidth <= 1024 && windowViewWidth > 600) {
            groupSize = 3;
            buttonWidthSelected = `${440 / 1110 * 100}%`;
            buttonWidthUnselected = `${290 / 1110 * 100}%`;
        }
        
        if (windowViewWidth <= 600 && windowViewWidth > 480) {
            // console.log('Group size 2');
            groupSize = 2;
            buttonWidthSelected = '55%';
            buttonWidthUnselected = '40%';
        }
        
        if (windowViewWidth <= 480) {
            // console.log('Group size 1');
            groupSize = 1;
            buttonWidthSelected = '100%';
            buttonWidthUnselected = '90%';
        }

        this.deselectAllButtons(buttonWidthUnselected);
        this.hideAllPanels();

        this.panelContainers.forEach(panel => {
            panel.children[0].style.marginTop = '0';
            const oldClassList = panel.className;
            const regex = /team-block__container-set-[0-9]/i;
            const newClassList = oldClassList.replace(regex, '');
            panel.className = newClassList;
        });

        let countGroupSize = 0;
        let countGroupSets = 0;

        teamMembers.forEach(item => {
            countGroupSize += 1;
            item.classList.add(`team-block__container-set-${countGroupSets}`);
            item.setAttribute('groupSet', `team-block__container-set-${countGroupSets}`);
            if (countGroupSize === groupSize) {
                countGroupSize = 0;
                countGroupSets += 1;
            }
        });

        window.addEventListener('resize', () => {
            windowViewWidth = window.innerWidth;

            if (windowViewWidth > 1024) {
                // console.log('Group size 4');
                groupSize = 4;
                buttonWidthSelected = `${350 / 1110 * 100}%`;
                buttonWidthUnselected = `${240 / 1110 * 100}%`;
            }

            if (windowViewWidth <= 1024 && windowViewWidth > 600) {
                groupSize = 3;
                buttonWidthSelected = `${440 / 1110 * 100}%`;
                buttonWidthUnselected = `${290 / 1110 * 100}%`;
            }

            if (windowViewWidth <= 600 && windowViewWidth > 480) {
                // console.log('Group size 2');
                groupSize = 2;
                buttonWidthSelected = '55%';
                buttonWidthUnselected = '40%';
            }

            if (windowViewWidth <= 480) {
                // console.log('Group size 1');
                groupSize = 1;
                buttonWidthSelected = '100%';
                buttonWidthUnselected = '90%';
            }

            this.deselectAllButtons(buttonWidthUnselected);
            this.hideAllPanels();

            this.panelContainers.forEach(panel => {
                const oldClassList = panel.className;
                const regex = /team-block__container-set-[0-9]/i;
                const newClassList = oldClassList.replace(regex, '');
                panel.children[0].style.marginTop = '0';
                panel.className = newClassList;
            });

            countGroupSize = 0;
            countGroupSets = 0;

            teamMembers.forEach(item => {
                countGroupSize += 1;
                item.classList.add(`team-block__container-set-${countGroupSets}`);
                item.setAttribute('groupSet', `team-block__container-set-${countGroupSets}`);
                if (countGroupSize === groupSize) {
                    countGroupSize = 0;
                    countGroupSets += 1;
                }
            });
        });

        this.panelContainers.forEach(container => {
            container.addEventListener('mouseover', event => {
                event.preventDefault();
                const siblings = [...container.parentElement.children];
                const gridArray = [];
                const target = container;

                this.mouseenterAnimate(siblings, gridArray, target);
            }, false);

            container.addEventListener('mouseleave', () => {
                container.classList.remove('latest-hover');
            });

            container.addEventListener('click', e => {
                e.preventDefault();

                const panelChild = container.children[0];
                const panelContent = document.getElementById(panelChild.getAttribute('aria-controls'));
                const siblings = [...container.parentElement.children];
                const gridArray = [];
                const { target } = e;
                const clickedElGroupSet = container.getAttribute('groupset');
                const clickedElGroupSetEls = [...document.querySelectorAll(`.${clickedElGroupSet}`)];

                container.children[0].style.marginTop = '0';
                siblings.forEach(brother => {
                    TweenLite.to(
                        brother.children[0],
                        0.5,
                        {
                            marginTop: '0',
                            ease: Power2.easeInOut,
                        },
                    );
                });

                if (container.classList.contains('is_selected')) {
                    const containerChild = container.querySelector('.team-block__content');
                    if (containerChild) {
                        TweenLite.to(
                            containerChild,
                            0.5,
                            {
                                height: 0,
                                width: 0,
                                opacity: 0,
                                ease: Power2.easeOut,
                            },
                        );
                    }

                    container.style.pointerEvents = 'none';
                    setTimeout(() => {
                        container.style.pointerEvents = 'auto';
                    }, 500);

                    const imageChangeOne = container.children[0].getAttribute('image-one') ? container.children[0].getAttribute('image-one').length ? `url(${container.children[0].getAttribute('image-one')})` : false : false;

                    container.children[0].style.backgroundImage = imageChangeOne;
                    siblings.forEach(brother => {
                        const imageChangeOneB = brother.children[0].getAttribute('image-one') ? brother.children[0].getAttribute('image-one').length ? `url(${brother.children[0].getAttribute('image-one')})` : false : false;

                        brother.classList.remove('active--not-active');
                        brother.children[0].style.backgroundImage = imageChangeOneB;
                    });
                    this.deselectAllButtons(buttonWidthUnselected);
                    this.hideAllPanels();

                    return false;
                }

                if (!container.classList.contains('is_selected')) {
                    // console.log('container.offsetHeight: ', container.children[0].offsetHeight);
                    // console.log(container.children[0]);
                    clickedElGroupSetEls.forEach(item => {
                        if (item !== container) {
                            // item.children[0].style.marginTop = '-50%';
                            const itemChild = item.children[0];
                            TweenLite.to(
                                itemChild,
                                0.5,
                                {
                                    marginTop: '25%',
                                    ease: Power2.easeInOut,
                                },
                            );
                        }
                    });
                }

                this.deselectAllButtons(buttonWidthUnselected);
                this.hideAllPanels();
                this.selectButton(container, siblings, gridArray, target, buttonWidthSelected);
                this.showPanel(panelContent);
            });
        });
    }

    mouseenterAnimate(siblings, gridArray, target) {
        siblings.forEach(child => {
            if (target !== child) {
                // -Create array of all elements (siblings) which are not current hover target
                child.classList.remove('latest-hover');
            }
        });

        if (target) {
            target.classList.add('latest-hover');
        }
    }

    clickAnimation(button, siblings) {
        button.classList.remove('active--not-active');
        siblings.forEach(child => {
            if (button !== child) {
                const childContent = child.querySelector('.team-block__content');
                // console.log(child);
                if (childContent) {
                    TweenLite.to(
                        childContent,
                        0.5,
                        {
                            height: 0,
                            width: 0,
                            opacity: 0,
                            ease: Power2.easeOut,
                        },
                    );
                }
                child.classList.add('active--not-active');
                // const imageChangeTwo = child.children[0].getAttribute('image-one') ? child.children[0].getAttribute('image-one').length ? `url(${child.children[0].getAttribute('image-one')})` : false : false;
                // child.children[0].style.backgroundImage = imageChangeTwo;
            }
        });
    }

    selectButton(button, siblings, gridArray, target, buttonWidthSelected) {
        button.style.marginTop = '0';
        button.style.pointerEvents = 'none';
        siblings.forEach(sibling => {
            sibling.style.pointerEvents = 'none';
        });
        button.classList.add('is_selected');

        const imageChangeThree = button.children[0].getAttribute('image-three')
            ? button.children[0].getAttribute('image-three').length
                ? `url(${button.children[0].getAttribute('image-three')})`
                : false
            : false;
        const buttonChild = button.querySelector('.team-block__content');
        imageChangeThree ? button.children[0].style.backgroundImage = imageChangeThree : false;
        TweenLite.to(
            button,
            0.5,
            {
                ease: Power2.easeInOut,
                width: buttonWidthSelected,
            },
        );

        if (buttonChild) {
            TweenLite.set(
                buttonChild,
                {
                    height: 'auto',
                    width: 'auto',
                    opacity: 1,
                },
            );

            TweenLite.from(
                buttonChild,
                0.5,
                {
                    height: 0,
                    width: 0,
                    ease: Power2.easeOut,
                    delay: 0.4,
                },
            );

            TweenLite.from(
                buttonChild,
                0.5,
                {
                    opacity: 0,
                    ease: Power2.easeOut,
                    delay: 1,
                },
            );
        }
        
        setTimeout(() => {
            button.style.pointerEvents = 'auto';
            siblings.forEach(sibling => {
                sibling.style.pointerEvents = 'auto';
            });
        }, 1000);
        this.clickAnimation(button, siblings, gridArray, target);
    }

    deselectAllButtons(buttonWidthUnselected) {
        this.panelContainers.forEach(button => {
            button.classList.remove('is_selected');
            TweenLite.to(
                button,
                0.5,
                {
                    ease: Power2.easeInOut,
                    width: buttonWidthUnselected,
                },
            );
        });
    }

    showPanel(panel) {
        panel ? panel.classList.add('is_selected') : false;
        panel ? panel.setAttribute('aria-hidden', false) : false;
    }

    hideAllPanels() {
        this.panelContents.forEach(block => {
            block.classList.remove('is_selected');
            block.setAttribute('aria-hidden', true);
        });
    }
}
