/**
 * Home videos - play on mouseenter / pause on mouseleave
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

const AnimateBorders = () => {
    const bordersArr = [...document.querySelectorAll('.border-animate')];
    if (bordersArr.length === 0) {
        return;
    }

    const borderAnimate = () => {
        for (let i = 0, n = bordersArr.length; i < n; i += 1) {
            if (bordersArr) {
                if (bordersArr[i].getBoundingClientRect().top + 40 < window.innerHeight) {
                    bordersArr[i].classList.add('border-animate-active');
                }
            }
        }
    };

    window.addEventListener('scroll', borderAnimate, false);
    window.addEventListener('load', borderAnimate, false);
};

export default AnimateBorders;
