import { TweenLite } from 'gsap/TweenMax';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Barba from 'barba.js';
import ScrollReveal from 'scrollreveal';
import TeamBlock from './TeamBlock';
import VimeoPlayer from './VimeoPlayer';
import ShowLandingOrder from './ShowLandingOrder';
import ShowRelatedVideos from './ShowRelatedVideos';
// import CountrySwitch from './CountrySwitch';
import {
    getWindowHeight, getWindowWidth, debounce, isPartiallyVisible,
} from '../utilities/functions';
import { HomeVideoPlayer } from './HomeVideoPlayer';
import AnimateBorders from './AnimateBorders';
import ShowArchiveFormSubmit from './ShowArchiveFormSubmit';
import FullPageInit from './FullPageInit';
import WordGenInit from './WordGenInit';
import { HomepageScrollEvents } from './HomepageScrollEvents';
import { MaintainUrlParams } from './MaintainUrlParam';
import VideoPlayer from './VideoPlayer';
import UnderlineJS from './UnderlineJS';
import { getNavUnderlineColour } from './UnderlineJS';
import FadeInElementsOnLoad from './FadeInElementsOnLoad';
import showsVideoHover from './ShowsVideoHover';
import LazyLoad from 'vanilla-lazyload';
import awardPopup from './AwardPopup';

export default class Transitions {
    constructor() {
        this.activeClass = 'barba';
        window.Barba = Barba;

        // Get original Pjax preventcheck, run this and extend
        Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
        Barba.Pjax.preventCheck = (evt, element) => {
            if (!Barba.Pjax.originalPreventCheck(evt, element)) {
                return false;
            }

            // Return true if has class
            return element.classList.contains(this.activeClass);
        };

        Barba.Pjax.init();
        Barba.Prefetch.init();

        Barba.Dispatcher.on('linkClicked', el => {
            el.parentElement.classList.add('is_active', 'current');
            // el.style.pointerEvents = 'none';
            // Add 'is_active', for the CSS of active page
            // Add 'current', which is added & removed during
            // transition to ascertain which page we are moving to
            const mainNav = document.getElementById('main-nav-list');
            mainNav.classList.add('transitioning');
        });

        const HideShowTransition = Barba.BaseTransition.extend({

            start() {
                Promise.all([this.newContainerLoading, this.zoomIn()])
                    .then(this.movePages.bind(this));
                ScrollReveal().destroy();
            },

            zoomIn() {
                const deferred = Barba.Utils.deferred();
                const obj = { y: window.pageYOffset };

                TweenLite.to(this.oldContainer, 0.1, {
                    // ease: Back.easeOut,
                    // scale: 1.01, - comment out zoom in for now
                    onComplete() {
                        deferred.resolve();
                        // console.log('Complete Zoom in');
                    },
                });

                return deferred.promise;
            },

            movePages() {
                const _this = this;
                const goingForward = true;

                // console.log('movePages');

                TweenLite.set(this.newContainer, {
                    ease: Power1.easeInOut,
                    visibility: 'visible',
                    position: 'fixed',
                    left: 0,
                    top: '100%',
                    right: 0,
                    onComplete() {
                        // console.log('New container movePages');
                        // - Hide homepage logo: start
                        // const headerLogo = document.querySelector('.site-head .logo');
                        // window.location.pathname === '/' ? headerLogo.style.opacity = 0 : headerLogo.style.opacity = 1;
                        // window.location.pathname === '/' ? : ;
                        // - Hide homepage logo: end
                    },
                });

                TweenLite.to(this.oldContainer, 1.75, { // - speed old container animates out of view
                    ease: Back.easeOut,
                    scale: goingForward ? 0.8 : 1,
                    opacity: goingForward ? 0 : 1,
                    onComplete() {
                        // console.log('Old container movePages');
                    },
                });

                TweenLite.to(this.newContainer, 0.7, { // - speed new container comes into view
                    ease: Power1.easeInOut,
                    top: 0,
                    onComplete() {
                        TweenLite.set(_this.newContainer, { clearProps: 'all' });
                        // console.log(window.location.pathname);
                        if (window.location.pathname !== '/') {
                            if (document.getElementById('fp-nav')) {
                                document.getElementById('fp-nav').style.display = 'none';
                            }
                        } else if (document.getElementById('fp-nav')) {
                            document.getElementById('fp-nav').style.display = 'block';
                        }

                        _this.done();
                        // console.log('New container movePages -- part 2');
                    },
                });
            },

            finish() {
                this.done();
            },
        });

        Barba.Pjax.getTransition = function () {
            return HideShowTransition;
        };

        Barba.Dispatcher.on('transitionCompleted', (currentStatus, oldStatus, container) => {
            // console.log('Transition complete');
            const mainNav = document.getElementById('main-nav-list');
            mainNav.classList.remove('transitioning');

            // Remove main nav underline if on footer page, and add underline to footer link
            const footerLinks = Array.from(document.querySelectorAll('.foot-nav .item'));
            const underline = document.getElementById('underlineJs');
            const windowLocation = window.location.pathname.split('/')[1];

            footerLinks.forEach(footerLink => {
                const linkTag = footerLink.querySelector('a');
                const linkHref = linkTag.getAttribute('href').replace('/', '').replace('?countrySelect=true', '');
                if (linkHref === windowLocation) {
                    // Hide main nav underline
                    underline.style.opacity = '0'
                    underline.style.transform = 'translateX(0px)'
                    // Add underline to footer link
                    linkTag.classList.add('is_active');
                } else {
                    linkTag.classList.remove('is_active');
                }
            })

            const navItems = Array.from(document.querySelectorAll('#main-nav-list .item'));
            navItems.forEach(item => {
                if (
                    window.location.pathname === '/' 
                    && window.location.pathname === (item.querySelector('.link').getAttribute('href'))
                ) {
                    navItems[0].classList.add('is_active', 'current');
                    underline.style.transition = '0.6s transform ease, 0.6s width ease, 0.6s background-color ease, 0.6s opacity ease';
                    underline.style.transform = `translateX(${navItems[0].offsetLeft - 1}px)`;
                    underline.style.width = `${navItems[0].offsetWidth + 3}px`;
                    underline.style.backgroundColor = getNavUnderlineColour(navItems.indexOf(navItems[0]));
                } else if (
                    item.querySelector('.link').getAttribute('href').replaceAll('/', '') !== '' 
                    && window.location.pathname.replaceAll('/', '').includes(item.querySelector('.link').getAttribute('href').replaceAll('/', ''))
                ) {
                    item.classList.add('is_active', 'current');
                    underline.style.transition = '0.6s transform ease, 0.6s width ease, 0.6s background-color ease, 0.6s opacity ease';
                    underline.style.transform = `translateX(${item.offsetLeft - 1}px)`;
                    underline.style.width = `${item.offsetWidth + 3}px`;
                    underline.style.backgroundColor = getNavUnderlineColour(navItems.indexOf(item));
                } else {
                    item.classList.remove('is_active');
                }
            });


            window.scrollTo(0, 0);

            const teamBlock = new TeamBlock(document.getElementById('team-block'));
            const showAwardsBlock = new TeamBlock(document.getElementById('showAwards'));
            const awardsBlock = new TeamBlock(document.getElementById('awards-list'));
            const vimeoPlayer = new VimeoPlayer(document.querySelector('.video-wrap'));
            const landingOrderOneThree = new ShowLandingOrder(document.querySelector('.shows-landing-list.ul_item_1.large__ShowThree'));
            const landingOrderTwoThree = new ShowLandingOrder(document.querySelector('.shows-landing-list.ul_item_2.large__ShowThree'));
            const vimeoRelatedVideos = new ShowRelatedVideos(document.getElementById('relatedVideos'));
            // const countrySwitch = new CountrySwitch(document.querySelector('.country-select'));
            const currentWidth = getWindowWidth();
            const currentHeight = getWindowHeight();
            const wordGenInit = WordGenInit();
            // UnderlineJS();
            showsVideoHover();
            awardPopup();
            const lazyLoader = new LazyLoad();
            lazyLoader.update();

            const teamBlockContainer = Array.from(document.querySelectorAll('.team-block__container'));
            const fadeInTeamBlock = new FadeInElementsOnLoad(teamBlockContainer);

            // console.log('WINDOW IS LOADED');
            // console.log('load: ', windowLocation);

            // const navItems = Array.from(document.querySelectorAll('#main-nav-list .item'));
            // navItems.forEach(item => {
            //     const itemHref = item.querySelector('a').getAttribute('href').replace('/', '');
            //     console.log('itemHref: ', itemHref);
            //     if (itemHref === windowLocation) {
            //         console.log('match... :', item);
            //         item.classList.add('is_active');
            //     } else {
            //         item.classList.remove('is_active');
            //     }
            // });

            const awards = Array.from(document.querySelectorAll('.award'));
            const fadeInAwards = new FadeInElementsOnLoad(awards);

            // Mapping nav bar to look for 'is_active' & 'current' class'
            const elems = document.querySelectorAll('#main-nav-list .item');
            const values = Array.prototype.map.call(elems, obj => {
                // if (!obj.classList.contains('current')) {
                //     // If there is no 'current' class, remove 'is_active' class
                //     obj.classList.remove('is_active');
                // }
                // Remove 'current' class when logic is complete
                obj.classList.remove('current');
                obj.querySelector('a').style.pointerEvents = 'auto';

                return obj.classList;
            });
            // Mapping nav bar to look for 'is_active' & 'current' class'

            const homepageScrollEvents = HomepageScrollEvents();
            const homeVideoPlayer = HomeVideoPlayer();
            const animateBorders = AnimateBorders();
            const showArchiveFormSubmit = new ShowArchiveFormSubmit(document.querySelector('#show-filters'));
            const fullPageInit = FullPageInit();
            const maintainUrlParams = MaintainUrlParams();

            const homeVideoPlayerT = new VideoPlayer(
                document.getElementById('videoContainer'),
                [document.getElementById('videoToPlayPause'), document.getElementById('videoToPlayPauseMobile')],
                document.getElementById('videoPlay'),
                document.getElementById('videoStop'),
            );

            const slideUp = {
                distance: '20px',
                origin: 'bottom',
                interval: 150,
            };

            const blockSubTitles = [
                ...document.querySelectorAll('.disclaimer'),
                ...document.querySelectorAll('.brief'),
            ]
            if (blockSubTitles && blockSubTitles.length > 0) {
                ScrollReveal().reveal(blockSubTitles, slideUp, { cleanup: true });
            }

            const blockTitles = document.querySelectorAll('.block-title');
            if (blockTitles && blockTitles.length > 0) {
                ScrollReveal().reveal(blockTitles, slideUp, { cleanup: true });
            }

            const showcaseItem = document.querySelectorAll('.showcase .list .item');
            if (showcaseItem && showcaseItem.length > 0) {

                const lazyLoad = (el) => {
                    const img = el.querySelectorAll('img');
                    img.forEach(image => {
                        if (image.dataset.src) {
                            image.src = image.dataset.src;
                        }
                    })
                }

                slideUp.beforeReveal = lazyLoad;
                ScrollReveal().reveal(showcaseItem, slideUp, { cleanup: true });
            }

            const relatedVideo = document.querySelectorAll('.related-video-container.item');
            if (relatedVideo && relatedVideo.length > 0) {
                ScrollReveal().reveal(relatedVideo, slideUp, { cleanup: true });
            }

            const contactItem = document.querySelectorAll('.contact-block-container .item');
            if (contactItem && contactItem.length > 0) {
                ScrollReveal().reveal(contactItem, slideUp, { cleanup: true });
            }
        });
    }
}