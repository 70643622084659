/**
 * Check for countrySelect URL query string & apply to href if present
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

export const GetUrlVars = () => {
    const vars = {};
    const parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
    });
    return vars;
};

export const GetUrlParam = (parameter, defaultvalue) => {
    let urlParameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
        urlParameter = GetUrlVars()[parameter];
    }
    return urlParameter;
};
