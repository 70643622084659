/**
 * Interact with the Vimeo player
 * so we can have nice play buttons / video overlay - Replace Brightcove
 * @author Matt Haynes
 * @package LoveProductions
 */

export default class VimeoPlayer {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.elem = elem;
        this.vimeoPlayer = this.elem.querySelector('iframe')
            && this.elem.querySelector('iframe').getAttribute('id');
        if (!this.vimeoPlayer) {
            return;
        }
        this.player = new Vimeo.Player(this.vimeoPlayer);
        this.vimeoPlayButton = document.getElementById('vimeoPlay');

        this.vimeoPlayButton.addEventListener('click', () => {
            this.play();
        });
    }

    play() {
        this.elem.classList.add('playing');
        setTimeout(() => {
            this.elem.querySelector('.content-head').style.display = 'none';
        }, 500);
        setTimeout(() => {
            const promise = this.player.play();

            if (promise !== undefined) {
                promise.then(() => {
                    // Autoplay started!
                }).catch(error => {
                    console.error(error);
                    // Autoplay was prevented.
                    // Show a "Play" button so that user can start playback.
                });
            }
        }, 150);
        this.player.on('play', () => {
            // console.log('played the video!');
        });
    }
}
