/**
 * Random word generator
 *
 * @author  --
 * @package Love Productions
 */

const WordGenInit = () => {
    const wordgen = document.getElementById('wordgen');
    if (wordgen === null) {
        return;
    }
    const homeHero = document.getElementById('homeHero');
    const words = document.getElementById('wordgen-words').innerHTML.split(',');
    const videoContainer = document.getElementById('videoContainer');
    const videoPlay = document.getElementById('videoPlay');
    const video = [document.getElementById('videoToPlayPause'), document.getElementById('videoToPlayPauseMobile')];
    const numWords = (words.length - 1);
    let pauseLength = 1250;
    let interval = 0;
    let currentWord = 0;
    let picks = [];
    let wordCount = 0;
    let loop = 0;

    const getRandomInt = function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const pickRandomWord = function pickRandomWord() {
        const potentialPick = getRandomInt(1, words.length - 1);

        if (picks.indexOf(potentialPick) !== -1) {
            return pickRandomWord();
        }

        return potentialPick;
    };

    const pickNewWord = function pickNewWord() {
        if (wordCount === numWords) {
            currentWord = 0;
            wordCount = 0;
            picks = [];
        } else {
            currentWord = pickRandomWord();
            wordCount += 1;
            picks.push(currentWord);
        }

        window.clearInterval(interval);
        let multiplier = 0.7;
        if (pauseLength < 500) multiplier = 0.9;
        else if (wordCount === 1) multiplier = 1;
        let intervalTime = pauseLength * multiplier;
        if (currentWord === 0) {
            loop += 1;
        }

        if (loop < 2) {
            interval = window.setInterval(changeWord, intervalTime);
            pauseLength = intervalTime;
        } else {
            setTimeout(() => {
                homeHero.classList.add('completed');
                wordgen.parentElement.classList.add('completed');
                videoContainer.classList.add('playing');
                videoPlay.classList.add('playing');
                if (video) {
                    video.forEach(vid => {
                        if (vid) {
                            vid.play()
                        }
                    });
                }

                restartFunc(homeHero, wordgen, videoContainer, videoPlay, video);
            }, 1500);
        }
    };

    const writeNewWord = function writeNewWord() {
        pickNewWord();
        const { length } = words[currentWord];

        wordgen.innerHTML = words[currentWord];
    };

    const changeWord = function changeWord() {
        const { length } = words[currentWord];

        wordgen.innerHTML = '';
        writeNewWord();
    };

    (function () {
        interval = window.setInterval(changeWord, pauseLength);
    }());
};

const restartFunc = (homeHero, wordgen, videoContainer, videoPlay, video) => {
    const triggerAnimation = () => {
        homeHero.classList.remove('completed');
        wordgen.parentElement.classList.remove('completed');
        videoContainer.classList.remove('playing');
        videoPlay.classList.remove('playing');

        if (video) {
            video.forEach(vid => {
                if (vid) {
                    vid.pause()
                }
            });
        }

        WordGenInit();
    }

    const timeout = setTimeout(triggerAnimation, 15000);

    let paused = false;
    const pauseAnimationLoopButton = document.getElementById('pauseAnimationLoop');

    pauseAnimationLoopButton?.addEventListener('click', () => {
        paused = !paused;

        if (paused) {
            clearTimeout(timeout);
            pauseAnimationLoopButton.classList.add('paused');
            pauseAnimationLoopButton.querySelector('span').innerHTML = 'Play text animation';
        } else {
            triggerAnimation();
            pauseAnimationLoopButton.classList.remove('paused');
            pauseAnimationLoopButton.querySelector('span').innerHTML = 'Pause text animation';
        }
    });
}

export default WordGenInit;
