/**
 * Fade in an array of elements on page load
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

import { TimelineLite } from 'gsap/TweenMax';

class FadeInElementsOnLoad {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.elem = elem;
        this.init();
    }

    init() {
        const tl = new TimelineLite();
        this.elem.forEach(element => {
            const child = new TimelineLite();
            child.to(element, 0.15, { y: 0, opacity: 1, });
            tl.add(child);
        });
    }
}

export default FadeInElementsOnLoad;
