import ScrollReveal from 'scrollreveal';
import {
    debounce, getWindowWidth, getWindowHeight, isPartiallyVisible,
} from './utilities/functions';
import MobileMenu from './components/MobileMenu';
import LocaleSwitch from './components/LocaleSwitch';
// import CountrySwitch from './components/CountrySwitch';
import OtherSeries from './components/OtherSeries';
import VimeoPlayer from './components/VimeoPlayer';
import ShowRelatedVideos from './components/ShowRelatedVideos';
import ShowFilters from './components/ShowFilters';
import TeamBlock from './components/TeamBlock';
import Transitions from './components/Transitions';
// import CookieBar from './components/CookieBar';
import ShowLandingOrder from './components/ShowLandingOrder';
import { HomeVideoPlayer } from './components/HomeVideoPlayer';
import AnimateBorders from './components/AnimateBorders';
import UnderlineJS from './components/UnderlineJS';
import ShowArchiveFormSubmit from './components/ShowArchiveFormSubmit';
import FullPageInit from './components/FullPageInit';
import WordGenInit from './components/WordGenInit';
import LogoAnimation from './components/LogoAnimation';
import { HomepageScrollEvents } from './components/HomepageScrollEvents';
import { EnableTabbing } from './components/EnableTabbing';
import SizzleLoader from './components/SizzleLoader';
import { MaintainUrlParams } from './components/MaintainUrlParam';
import DetectIE from './components/DetectIE';
import VideoPlayer from './components/VideoPlayer';
import FadeInElementsOnLoad from './components/FadeInElementsOnLoad';
import CalculateTrueHeight from "@mattgh9152/calculatetrueheight";
import checkSession from './components/CheckSessions';
import showsVideoHover from './components/ShowsVideoHover';
import LazyLoad from 'vanilla-lazyload';
import awardPopup from './components/AwardPopup';
import awardsShowsList from './components/AwardsShowsList';

let currentWidth = getWindowWidth();
let currentHeight = getWindowHeight();
let mobileMenu = false;

window.addEventListener('load', debounce(() => {
    const transitionController = new Transitions();
    const newWidth = getWindowWidth();
    const localeSwitch = new LocaleSwitch(document.getElementById('locale-switch-toggle'), document.getElementById('locale-switch-list'));
    const otherSeries = new OtherSeries(document.getElementById('other-series-toggle'), document.getElementById('other-series-list'));
    const showFilters = new ShowFilters(document.getElementById('show-filters'));
    const teamBlock = new TeamBlock(document.getElementById('team-block'));
    const awardsBlock = new TeamBlock(document.getElementById('awards-list'));
    const showAwardsBlock = new TeamBlock(document.getElementById('showAwards'));
    // const cookieBar = new CookieBar(document.querySelector('.cookie-notification'));
    const vimeoPlayer = new VimeoPlayer(document.querySelector('.video-wrap')); 
    // const countrySwitch = new CountrySwitch(document.querySelector('.country-select'));
    const sizzleLoader = new SizzleLoader(document.getElementById('sizzleLoader'));
    mobileMenu = new MobileMenu(document.getElementById('main-nav-toggle'), document.getElementById('main-nav-list'));
    checkSession();
    showsVideoHover();
    awardPopup();
    awardsShowsList();
    const lazyLoader = new LazyLoad();
    lazyLoader.update();

    const homeMainVideoPlayer = new VideoPlayer(
        document.getElementById('videoContainer'),
        [document.getElementById('videoToPlayPause'), document.getElementById('videoToPlayPauseMobile')],
        document.getElementById('videoPlay'),
        document.getElementById('videoStop'),
    );

    // cookieBar.initCheck();

    showFilters.updateActiveFilters();

    const newHeight = getWindowHeight();
    const videoStop = document.getElementById('videoStop');
    if (videoStop) {
        videoStop.style.transform = `translateY(${newHeight - 65}px)`;
    }
}, 10), false);

window.addEventListener('resize', () => {
    const newHeight = getWindowHeight();
    const videoStop = document.getElementById('videoStop');
    if (videoStop) {
        videoStop.style.transform = `translateY(${newHeight - 65}px)`;
    }
})

window.addEventListener('resize', debounce(() => {
    const newWidth = getWindowWidth();
    const newHeight = getWindowHeight();

    if (newHeight !== currentHeight && newWidth === currentWidth) {
        return false;
    }

    if (newWidth === currentWidth) {
        return false;
    }

    currentWidth = newWidth;
    currentHeight = newHeight;

    if (newWidth < 600) {
        if (mobileMenu) {
            mobileMenu.resize();
            mobileMenu.init();
        }
    }

    if (newWidth >= 600) {
        if (mobileMenu) {
            mobileMenu.destroy();
        }
    }
}, 500), false);

const fullPageInit = FullPageInit();
const homepageScrollEvents = HomepageScrollEvents();
const wordGenInit = WordGenInit();
const underlineJS = UnderlineJS();
const logoAnimation = new LogoAnimation(document.getElementById('headerLogo'), document.getElementById('headerLogoUSA'));
const homeVideoPlayer = HomeVideoPlayer();
const showArchiveFormSubmit = new ShowArchiveFormSubmit(document.querySelector('#show-filters'));
const animateBorders = AnimateBorders();
const enableTabbing = EnableTabbing();
const vimeoRelatedVideos = new ShowRelatedVideos(document.getElementById('relatedVideos'));
const landingOrderOneThree = new ShowLandingOrder(document.querySelector('.shows-landing-list.ul_item_1.large__ShowThree'));
const landingOrderTwoThree = new ShowLandingOrder(document.querySelector('.shows-landing-list.ul_item_2.large__ShowThree'));
const maintainUrlParams = MaintainUrlParams();
const detectIe = DetectIE();

const teamBlockContainer = Array.from(document.querySelectorAll('.team-block__container'));
const fadeInTeamBlock = new FadeInElementsOnLoad(teamBlockContainer);

const awards = Array.from(document.querySelectorAll('.award'));
const fadeInAwards = new FadeInElementsOnLoad(awards);

// Remove main nav underline if on footer page, and add underline to footer link
const footerLinks = Array.from(document.querySelectorAll('.foot-nav .item'));
const underline = document.getElementById('underlineJs');
const windowLocation = window.location.pathname.split('/')[1];

footerLinks.forEach(footerLink => {
    const linkTag = footerLink.querySelector('a');
    const linkHref = linkTag.getAttribute('href').replace('/', '').replace('?countrySelect=true', '');
    if (linkHref === windowLocation) {
        // Hide main nav underline
        underline.style.opacity = '0'
        underline.style.transform = 'translateX(0px)'
        // Add underline to footer link
        linkTag.classList.add('is_active');
    } else {
        linkTag.classList.remove('is_active');
    }
})

ScrollReveal.debug = true;

// - Reveal items on scroll - this should always be last in the file: start
const slideUp = {
    distance: '20px',
    origin: 'bottom',
    interval: 50,
};

const blockSubTitles = [
    ...document.querySelectorAll('.disclaimer'),
    ...document.querySelectorAll('.brief'),
]
if (blockSubTitles && blockSubTitles.length > 0) {
    ScrollReveal().reveal(blockSubTitles, slideUp, { cleanup: true });
}

const blockTitles = document.querySelectorAll('.block-title');
if (blockTitles && blockTitles.length > 0) {
    ScrollReveal().reveal(blockTitles, slideUp, { cleanup: true });
}

const showcaseItem = document.querySelectorAll('.showcase .list .item');
if (showcaseItem && showcaseItem.length > 0) {
    const lazyLoad = (el) => {
        const img = el.querySelectorAll('img');
        img.forEach(image => {
            if (image.dataset.src) {
                image.src = image.dataset.src;
            }
        })
    }
    slideUp.beforeReveal = lazyLoad;
    ScrollReveal().reveal(showcaseItem, slideUp, { cleanup: true });
}

const relatedVideo = document.querySelectorAll('.related-video-container.item');
if (relatedVideo && relatedVideo.length > 0) {
    ScrollReveal().reveal(relatedVideo, slideUp, { cleanup: true });
}

const contactItem = document.querySelectorAll('.contact-block-container .item');
if (contactItem && contactItem.length > 0) {
    ScrollReveal().reveal(contactItem, slideUp, { cleanup: true });
}
// - Reveal items on scroll - this should always be last in the file: end
