const parseAwardsHTML = (data) => {
    return data.map(award => {
        const parsedAward = JSON.parse(award.querySelector('.singleAwardJSON').innerHTML);
        const {
            awardname: awardName,
            customimage: customImage,
            customimagealttext: customImageAltText,
        } = award.dataset;

        const singleAwardGroup = parsedAward.map(anAward => {
            return (`<div class="singleAward__nomination ${anAward.awardnominated.toLowerCase()}">
                <span>${anAward.awardnominated}</span>
                <span>${anAward.awardnominatedvalue}</span>
                </div>`)
        }).join('');

        let useCustomImage = false;
        let styles = '';
        let classes = '';
        if (award.classList.contains('singleAward--otheraward') && customImage) {
            useCustomImage = true;
        }

        if (useCustomImage) {
            styles = `background-image: url(${customImage})`;
            classes = 'using-custom-image';
        }

        let useCustomAwardName = false;
        if (award.classList.contains('singleAward--otheraward') && customImageAltText) {
            useCustomAwardName = true;
        }

        const wrappedHTML = `<div class="${award.classList} ${classes}">
        <h2 class="vh">${useCustomAwardName ? customImageAltText : awardName}</h2>
        <div class="custom-image" style="${styles}"></div>
        ${singleAwardGroup}
        </div>`
        return wrappedHTML;
    }).join('');
}

export default parseAwardsHTML;
