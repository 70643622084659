import { makeTabbable, getWindowWidth, debounce } from '../utilities/functions.js';

/**
 * Mobile menu class. Plus added accessibility
 * @author Phil Steer
 * @package LoveProductions
 */
class MobileMenu {
    constructor(toggle, nav) {
        if (nav === null || toggle === null) {
            return;
        }

        this.toggle = toggle;
        this.nav = nav;
        this.status = this.toggle.querySelector('.status');
        this.currentWidth = getWindowWidth();
        this.docTabbable = document.querySelectorAll('a, button, input, textarea, select, label');
        this.navTabbable = this.nav.querySelectorAll('a, button, input, textarea, select, label');
        this.docTabbable = Array.prototype.slice.call(this.docTabbable);
        this.navTabbable = Array.prototype.slice.call(this.navTabbable);
        this.isInitialised = false;

        if (this.currentWidth < 768) {
            this.init();
        }
    }

    init() {
        this.setHeight();

        if (this.isInitialised) return null;

        this.ToggleMenu = () => {
            if (this.toggle.classList.contains('is_active')) {
                this.closeMenu();
                makeTabbable(this.docTabbable, this.navTabbable);
            } else {
                this.openMenu();
                makeTabbable(this.navTabbable, this.docTabbable);
            }
        };

        this.toggle.addEventListener('click', this.ToggleMenu);

        document.querySelectorAll('#main-nav-list li a')
            .forEach(navLink => navLink.addEventListener('click', () => {
                this.closeMenu();
            }));

        this.isInitialised = true;
    }

    closeMenu() {
        // console.log('closing menu');
        this.nav.style.height = '0px';
        this.nav.setAttribute('aria-hidden', true);
        this.nav.classList.remove('is_active');
        this.toggle.setAttribute('aria-pressed', false);
        this.toggle.classList.remove('is_active');
        this.status.innerHTML = 'closed';
    }

    openMenu() {
        // console.log('opening menu');
        this.nav.style.height = `${this.nav.getAttribute('data-height')}px`;
        this.nav.setAttribute('aria-hidden', false);
        this.nav.classList.add('is_active');
        this.toggle.setAttribute('aria-pressed', true);
        this.toggle.classList.add('is_active');
        this.status.innerHTML = 'open';
    }

    setHeight() {
        this.nav.setAttribute('style', 'auto');

        const styles = window.getComputedStyle(this.nav);
        const menuHeight = parseInt(this.nav.clientHeight, 10) + 90;

        this.nav.setAttribute('data-height', menuHeight);
        this.closeMenu();
        this.nav.parentElement.classList.add('is_ready');
    }

    resize() {
        if (this.isInitialised) {
            this.setHeight();
        }
    }

    destroy() {
        // console.log('destroyed');
        if (this.isInitialised) {
            this.nav.removeAttribute('style');
            this.nav.setAttribute('aria-hidden', false);
            this.nav.classList.remove('is_active');
            this.toggle.setAttribute('aria-pressed', false);
            this.toggle.classList.remove('is_active');
            makeTabbable(this.docTabbable, this.navTabbable);
        }
    }
}

export default MobileMenu;
