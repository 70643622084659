import { GetUrlParam } from './GetUrlParam';

/**
 * Check for URL Params & apply to all links if so
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

const getUrlParam = GetUrlParam('countrySelect', false);

export const MaintainUrlParams = () => {
    const allHref = [...document.querySelectorAll('a')];

    if (getUrlParam) {
        // && !link.classList.contains('link--usa')
        allHref.forEach(link => {
            if (!link.classList.contains('link--uk')
            && !link.classList.contains('external')
            && !link.classList.contains('no-rewrite')
            && !link.parentNode.parentNode.parentNode.classList.contains('fp-right')
            && link.getAttribute('target') !== '_blank') {
                const oldLink = link.getAttribute('href').replace(/\?countrySelect=true/i, '');
                link.setAttribute('href', `${oldLink}?countrySelect=true`);
            }
        });
    }
};
