/**
 * Handle content to be displayed on awards page pop-ups
 * because ModX doesn't want to parse Migx correctly...
 *
 * @author Matt <matt@joipolloi.com>
 * @package Love Productions
 */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import parseAwardsHTML from './AwardsParseHTML';
import checkIfImagesEnabled from './CheckIfImagesEnabled';

const awardPopup = () => {
    const awardLauncherButtons = Array.from(document.querySelectorAll('.showAwardItem'));
    const popup = document.getElementById('popup');
    if (!awardLauncherButtons || !popup) return;
    const closeButton = popup.querySelector('.popup__close');
    if (!closeButton) return;
    let scrollYPos = 0;
    let focusItem = null;
    const imagesEnabled = checkIfImagesEnabled();

    if (!imagesEnabled) {
        popup.classList.add('images-disabled');
    }

    awardLauncherButtons.map(button => {
        button.addEventListener('click', () => {
            const idToFetch = button.querySelector('button').dataset.id;
            const showName = button.querySelector('button').dataset.showname;
            scrollYPos = window.scrollY;
            focusItem = button.querySelector('button');

            fetch(`/${idToFetch}`)
                .then(data => {
                    return data.text();
                })
                .then(data => {
                    popup.querySelector('.popup__inner').innerHTML = data;
                    popup.querySelector('.popup__inner').scrollTo(0, 0);
                })
                .then(() => {
                    const singleAwards = Array.from(document.querySelectorAll('.singleAward'));
                    const theIntroContent = document.getElementById('theIntroContent');

                    let introContentHTML = '';
                    if (theIntroContent && theIntroContent.innerHTML) {
                        introContentHTML = theIntroContent.innerHTML;
                    }

                    const awardsHTML = parseAwardsHTML(singleAwards);

                    return `<div class="popup__content"><div class="popup__image"><img src="${button.querySelector('.image').src}" /></div><div class="popup__content--content"><h2>${showName}</h2>${introContentHTML}${awardsHTML}</div></div>`;
                })
                .then(newHTML => {
                    popup.querySelector('.popup__inner').innerHTML = newHTML;
                    popup.classList.add('active');
                    popup.setAttribute('aria-hidden', false);
                    popup.setAttribute('aria-hidden-focus', false);
                    closeButton.focus();
                    disableBodyScroll(popup.querySelector('.popup__inner'), { allowTouchMove: () => true });
                });
        });
    });

    const handleClose = () => {
        if (!popup) return;

        if (popup.classList.contains('active')) {
            console.log('closing');
            popup.classList.remove('active');
            popup.setAttribute('aria-hidden', true);
            popup.setAttribute('aria-hidden-focus', true);
            enableBodyScroll(popup.querySelector('.popup__inner'));
            window.scrollTo(0, scrollYPos);
            focusItem?.focus();
        }
    }

    closeButton.addEventListener('click', () => {
        handleClose();
    });

    document.addEventListener('click', (event) => {
        const inner = popup.querySelector('.popup__inner');

        if (!inner.contains(event.target)) {
            handleClose();
        }
    });

    document.addEventListener('keydown', (event) => {
        const inner = popup.querySelector('.popup__inner');

        if (event.key === 'Escape') {
            handleClose();
        }

        if (event.key === 'ArrowDown') {
            inner.scrollTop += 100;
        }

        if (event.key === 'ArrowUp') {
            inner.scrollTop -= 100;
        }
    });
};

export default awardPopup;
