/**
 * Basic video player class
 *
 * @author Matt Haynes
 * @package LoveProductions
 */

class VideoPlayer {
    constructor(elem, video, playVideo, stopVideo) {
        if (!elem || !video || !playVideo || !stopVideo) {
            return;
        }

        this.videoContainer = elem;
        this.video = video;
        this.playVideo = playVideo;
        this.stopVideo = stopVideo;

        this.playVideo.addEventListener('click', () => {
            this.togglePlay();
        });

        this.stopVideo.addEventListener('click', () => {
            this.togglePlay();
        });
    }

    togglePlay() {
        const isVideoPlaying = video => !!(
            video.currentTime > 0
            && !video.paused
            && !video.ended
            && video.readyState > 2
        );

        if (isVideoPlaying(this.video[0])) {
            this.video.forEach(vid => vid.pause());
            this.videoContainer.classList.remove('playing');
            this.playVideo.classList.remove('playing');
        } else {
            this.video.forEach(vid => {
                const promise = vid.play()

                if (promise !== undefined) {
                    promise.then(() => {
                        // Autoplay started!
                        this.videoContainer.classList.add('playing');
                        this.playVideo.classList.add('playing');
                    }).catch(error => {
                        // Autoplay was prevented.
                        console.error(error);
                    });
                }
            });            
        }
    }
}

export default VideoPlayer;
