/**
 * Create individual video players per related video on the individual show pages
 * @author Matt Haynes
 * @package LoveProductions
 */

export default class ShowRelatedVideos {
    constructor(elem) {
        if (!elem) {
            return;
        }
        this.elem = elem;
        this.videoList = this.elem.querySelector('.related-video-list');
        this.init();
    }

    init() {
        const videoIdList = this.videoList.innerHTML.split(',');

        videoIdList.forEach(id => {
            const newLi = document.createElement('li');
            const newIframe = document.createElement('iframe');

            newLi.classList.add('related-video-container', 'item');
            newIframe.setAttribute('src', `https://www.youtube.com/embed/${id}`);

            newLi.appendChild(newIframe);
            this.elem.appendChild(newLi);
        });
    }
}
