import parseAwardsHTML from './AwardsParseHTML';

const awardsShowsList = () => {
    const awardsListContainer = document.querySelector('.showcase-awards');
    if (!awardsListContainer) return;

    const singleAwards = Array.from(document.querySelectorAll('.singleAward'));
    const awardsHTML = parseAwardsHTML(singleAwards);

    awardsListContainer.innerHTML = `<h2 class="block-title">Awards</h2>${awardsHTML}`;
};

export default awardsShowsList;
