/**
 * Password protected pages - redirect to homepage after 15 minutes.
 * Session is cancelled in PHP so will need to log back in.
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

const checkSession = () => {
    const check = document.getElementById('checkSession');
    if (!check) return;

    (function() {

        const idleDurationSecs = 900;    // X number of seconds
        const redirectUrl = '/';  // Redirect idle users to this URL
        let idleTimeout; // variable to hold the timeout, do not modify
    
        const resetIdleTimeout = function() {
    
            // Clears the existing timeout
            if(idleTimeout) clearTimeout(idleTimeout);
    
            // Set a new idle timeout to load the redirectUrl after idleDurationSecs
            idleTimeout = setTimeout(() => location.href = redirectUrl, idleDurationSecs * 1000);
        };
    
        // Init on page load
        resetIdleTimeout();
    
        // Reset the idle timeout on any of the events listed below
        ['click', 'touchstart', 'mousemove'].forEach(evt => 
            document.addEventListener(evt, resetIdleTimeout, false)
        );
    
    })();
};

export default checkSession;
