/**
 * Order the show landing order page elements to position correctly regardless of which image
 * the admin selects to be the "large" image...
 * @author Matt Haynes
 * @package LoveProductions
 */

export default class ShowLandingOrder {
    constructor(elem) {
        if (!elem) {
            return;
        }
        this.elem = elem;
        this.init();
    }

    init() {
        let arr = [];

        switch (this.elem) {
        case document.querySelector('.shows-landing-list.ul_item_1.large__ShowThree'):
        case document.querySelector('.shows-landing-list.ul_item_2.large__ShowThree'):
            arr = [0, 2, 1];
            break;
        default:
            arr = [0, 1, 2];
        }

        const wrapper = this.elem;
        const items = wrapper.children;
        const elements = document.createDocumentFragment();

        arr.forEach(idx => {
            elements.appendChild(items[idx].cloneNode(true));
        });

        wrapper.innerHTML = null;
        wrapper.appendChild(elements);
    }
}
