import { debounce, getWindowWidth } from '../utilities/functions';

/**
 * Map the main nav bar to display the underline
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

export const getNavUnderlineColour = index => {
    let colour = '#fff';
    switch (index) {
        case 0:
            colour = '#FFB5E8';
            break;
        case 1:
            colour = '#6EB5FF';
            break;
        case 2:
            colour = '#45d96c';
            break;
        case 3:
            colour = '#f3883d';
            break;
        case 4:
            colour = '#85E3FF';
            break;
        case 5:
            colour = '#ca6bdb';
            break;
        default:
            colour = '#000';
    }

    return colour;
};

const calcUnderlinePosition = (underline, item, navItems) => {
    if (underline && item && navItems) {
        underline.style.transform = `translateX(${item.offsetLeft - 1}px)`;
        underline.style.width = `${item.offsetWidth + 3}px`;
        underline.style.backgroundColor = getNavUnderlineColour(navItems.indexOf(item));
    }
    underline.style.transition = '0.6s transform ease, 0.6s width ease, 0.6s background-color ease, 0.6s opacity ease';
    underline.style.opacity = '1';
}

const getWindowLocation = () => {
    return window.location.pathname.split('/')[1];
}

const isTouchDevice = () => {
    return (('ontouchstart' in window)
        || (navigator.maxTouchPoints > 0)
        || (navigator.msMaxTouchPoints > 0));
}

const UnderlineJS = () => {
    const navItems = Array.from(document.querySelectorAll('#main-nav-list .item'));
    const footerLinks = Array.from(document.querySelectorAll('.foot-nav .item a'));
    const extraItems = document.querySelectorAll('a.barba');
    const underline = document.getElementById('underlineJs');

    const footerLinkHrefs = footerLinks.map(footerLink => {
        return footerLink.getAttribute('href').replace('/', '').replace('?countrySelect=true', '');
    });
    footerLinkHrefs.push('password-protected');

    const mainNavUnderline = () => {
        underline.style.opacity = '1';

        navItems.forEach(item => {
            if (item.classList.contains('social-media')) return;

            item.onmouseover = () => {
                if (!isTouchDevice()) {
                    underline.style.transition = '0.6s transform ease, 0.6s width ease, 0.6s background-color ease, 0.6s opacity ease';
                    underline.style.opacity = '1';
                    underline.style.backgroundColor = getNavUnderlineColour(navItems.indexOf(item));

                    navItems.forEach(itemInside => {
                        itemInside.classList.remove('is_active');
                    });
                    item.classList.add('is_active');
                    calcUnderlinePosition(underline, item, navItems);
                }
            };

            item.onmouseleave = () => {
                const itemHref = item.querySelector('a').getAttribute('href').replace('/', '');
                itemHref.replace('?countrySelect=true', '');

                if (`${itemHref}` !== getWindowLocation()) {
                    item.classList.remove('is_active');

                    if (footerLinkHrefs.indexOf(getWindowLocation()) !== -1) {
                        underline.style.opacity = '0'
                    } else {
                        navItems.forEach(itemInside => {
                            const itemInsideHref = itemInside.querySelector('a')
                                .getAttribute('href')
                                .replace('/', '')
                                .replace('?countrySelect=true', '');
                            if (`${itemInsideHref}` === getWindowLocation()) {
                                itemInside.classList.add('is_active');
                                calcUnderlinePosition(underline, itemInside, navItems);
                                underline.style.backgroundColor = getNavUnderlineColour(
                                    navItems.indexOf(itemInside),
                                );
                            }
                        });
                    }
                }
            };
        });
    };

    // comment out because this is now handled in transitions.js: transitionCompleted
    // navItems.forEach(item => {
    // item.addEventListener('click', () => {
    // underline.style.left = `translateX(${item.offsetLeft - 1}px)`;
    // underline.style.width = `${item.offsetWidth + 3}px`;
    // // remove if they don't want it permanent
    // underline.style.backgroundColor = getNavUnderlineColour(navItems.indexOf(item));
    // }, false);
    // });

    let extraItemHref = null;
    extraItems.forEach(item => {
        const handlePress = () => {
            extraItemHref = item.getAttribute('href').split('/')[0].replace('/', '');
            underline.style.opacity = 0;

            navItems.forEach(insideItem => {
                const itemHref = insideItem.querySelector('a').getAttribute('href').replace('/', '');
                if (extraItemHref === itemHref) {
                    insideItem.classList.add('is_active', 'current');
                    mainNavUnderline();
                }
            });
        }
        item.addEventListener('click', handlePress);
        item.addEventListener('touchstart', handlePress);
    });

    window.addEventListener('load', debounce(() => {
        mainNavUnderline();

        navItems.forEach(itemInside => {
            const itemInsideHref = itemInside.querySelector('a')
                .getAttribute('href')
                .replace('/', '')
                .replace('?countrySelect=true', '');
            if (`${itemInsideHref}` === getWindowLocation()) {
                itemInside.classList.add('is_active');
                calcUnderlinePosition(underline, itemInside, navItems);
                underline.style.backgroundColor = getNavUnderlineColour(
                    navItems.indexOf(itemInside),
                );
            }
        });

        const item = navItems.filter(navItem => navItem.querySelector('a').getAttribute('href').replace('/', '') === window.location.pathname.replaceAll('/', ''))[0];
        calcUnderlinePosition(underline, item, navItems);
    }, 25, false));

    window.addEventListener('resize', debounce(() => {
        const newWidth = getWindowWidth();
        if (newWidth <= 600) {
            underline.style.opacity = '0';
            return;
        }
        mainNavUnderline();
    }, 25, false));
};

export default UnderlineJS;
