/**
 * Switch between US and UK fake locales
 * @author Phil Steer
 * @package LoveProductions
 */
export default class LocaleSwitch {
    constructor(toggle, linkList) {
        if (!toggle || !linkList) {
            return;
        }

        this.toggle = toggle;
        this.linkList = linkList;
        this.links = this.linkList.querySelectorAll('.link');
        this.firstLink = this.links[0];
        this.links = Array.prototype.slice.call(this.links);
        this.setup();
    }

    setup() {
        this.toggle.addEventListener('click', () => {
            if (this.toggle.classList.contains('is_pressed')) {
                this.closeMenu();
            } else {
                this.openMenu();
            }
        });

        this.links.forEach(link => {
            link.setAttribute('tabindex', -1);
        });
    }

    closeMenu() {
        this.toggle.setAttribute('aria-pressed', false);
        this.toggle.classList.remove('is_pressed');
        this.linkList.setAttribute('aria-hidden', true);
        this.linkList.classList.remove('is_active');
        this.toggle.focus();

        this.links.forEach(link => {
            link.setAttribute('tabindex', -1);
        });
    }

    openMenu() {
        this.toggle.setAttribute('aria-pressed', true);
        this.toggle.classList.add('is_pressed');
        this.linkList.setAttribute('aria-hidden', false);
        this.linkList.classList.add('is_active');
        this.firstLink.focus();

        this.links.forEach(link => {
            link.removeAttribute('tabindex');
        });
    }
}
