import { isInViewport } from '../utilities/functions';

/**
 * Homepage scroll events
 * -> Needs to be refactored further
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

export const HomepageScrollEvents = () => {
    const mainHome = document.getElementById('mainHome');
    if (!mainHome) {
        return;
    }
    // Homepage progress marker; scroll down
    // -Pip container
    const homepageProgressTracker = document.getElementById('homepageProgressTracker');
    // -NodeList of elements to check
    const homeBlocks = document.querySelectorAll('.home-block');
    // -Additional element (header) to add to check
    const homeHero = document.querySelectorAll('.hero-image');
    // -Combine the above two NodeList into one array - count of this used to created pips
    const homeBlocksArray = [...homeHero, ...homeBlocks];

    const clickScroll = event => {
        const scrollItem = document.querySelector(`.${event.target.id}`);
        zenscroll.to(scrollItem);
    };

    // Dynamically create the progress indicators, based on amount of elements to scroll through
    for (let i = 0, n = homeBlocksArray.length; i < n; i += 1) {
        const circle = document.createElement('div');

        circle.setAttribute('class', 'circle');
        circle.setAttribute('id', `circle-${i}`);
        if (homepageProgressTracker) {
            homepageProgressTracker.appendChild(circle);
        }
        circle.addEventListener('click', clickScroll, false);
    }

    let checkCircle;
    const titleArr = [];
    const subtitleArr = [];
    // - homeCheckScroll checks how far down you are scrolled and highlights the correct marker
    // - also extended to fade/slide into view the titles of each content segment
    const homeCheckScroll = () => {
        for (let i = 0, n = homeBlocksArray.length; i < n; i += 1) {
            if (homeBlocksArray[i]) {
                homeBlocksArray[i].classList.add(`circle-${i}`);
                checkCircle = document.getElementById(`circle-${i}`);
                if (homeBlocksArray[i].getBoundingClientRect().top - 200 <= 0
                    && homeBlocksArray[i].getBoundingClientRect().bottom - 200 >= 0
                ) {
                    if (checkCircle) {
                        checkCircle.classList.add('active');
                    }
                    if (titleArr.indexOf(homeBlocksArray[i].querySelector('.title')) === -1
                        && titleArr.indexOf(homeBlocksArray[i].querySelector('.title'))
                    ) {
                        titleArr.push(homeBlocksArray[i].querySelector('.title'));
                    }
                    if (subtitleArr.indexOf(homeBlocksArray[i].querySelector('.flip-container')) === -1 && subtitleArr.indexOf(homeBlocksArray[i].querySelector('.flip-container'))) {
                        subtitleArr.push(homeBlocksArray[i].querySelector('.flip-container'));
                    }
                } else if (isInViewport(homeBlocksArray[i])) {
                    if (titleArr.indexOf(homeBlocksArray[i].querySelector('.title')) === -1
                        && titleArr.indexOf(homeBlocksArray[i].querySelector('.title'))
                    ) {
                        titleArr.push(homeBlocksArray[i].querySelector('.title'));
                    }
                    if (subtitleArr.indexOf(homeBlocksArray[i].querySelector('.flip-container')) === -1
                        && subtitleArr.indexOf(homeBlocksArray[i].querySelector('.flip-container'))
                    ) {
                        subtitleArr.push(homeBlocksArray[i].querySelector('.flip-container'));
                    } else if (checkCircle) {
                        checkCircle.classList.remove('active');
                    }
                }
            }
        }
    };
    window.addEventListener('scroll', homeCheckScroll, false);
    // -- Homepage progress marker; scroll down
    // -Homepage -- Title Height scroll -- START
    const titles = titleArr;
    const subtitles = subtitleArr;

    const calcTitleHeight = () => {
        titles.forEach(title => {
            if (title === null) {
                return;
            }
            setTimeout(() => {
                const titleHeight = `${title.clientHeight}px`;
                title.parentElement.style.height = titleHeight;
            }, 500);
        });
        subtitles.forEach(subtitle => {
            if (subtitle === null) {
                return;
            }
            setTimeout(() => {
                subtitle.style.top = '0px';
            }, 500);
        });
    };
    window.addEventListener('resize', calcTitleHeight, false);
    window.addEventListener('load', calcTitleHeight, false);
    window.addEventListener('scroll', calcTitleHeight, false);
    // -Homepage -- Title Height scroll -- END
};
