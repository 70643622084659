/**
 * Show Archive Page - Filters auto submit on change
 *
 * @author  Matt Haynes <matthew.haynes@joipolloi.com>
 * @package Love Productions
 */

export default class ShowArchiveFormSubmit {
    constructor(elem) {
        if (!elem) {
            return;
        }
        this.elem = elem;
        this.formOptions = [...this.elem.querySelectorAll('select')];
        this.init();
    }

    init() {
        this.formOptions.forEach(select => {
            select.addEventListener('change', () => {
                this.elem.submit();
            }, false);
        });
    }
}
