export default class ShowFilters {
    constructor(elem) {
        if (!elem) {
            return;
        }

        this.filters = elem;
        this.yearElem = document.getElementById('year-list');
        this.categoryElem = document.getElementById('category-list');
        this.countryElem = document.getElementById('region-list');
        this.countrySelectElem = document.getElementById('country-select');
        this.resetFilters = document.getElementById('reset-filters');
        this.queryString = window.location.search;
        this.regExYear = /&?ShowYear=([\d]*)?(&|$)/;
        this.regExCategory = /&?ShowCategory=([\w\+]*)?(&|$)/;
        this.regExCountry = /&?ShowCountry=(uk|us)?(&|$)/;
        this.regExcountrySelect = /&?countrySelect=(true|false)?(&|$)/;
        this.year = null;
        this.category = null;
        this.country = null;
        this.countrySelect = null;
    }

    updateActiveFilters() {
        if (this.queryString === null || this.queryString === undefined || this.queryString === '') {
            return;
        }

        this.year = this.queryString.match(this.regExYear);
        this.category = this.queryString.match(this.regExCategory);
        this.country = this.queryString.match(this.regExCountry);
        this.countryselect = this.queryString.match(this.regExcountrySelect);

        if (this.year !== null && this.year !== '' && this.year !== undefined) {
            this.setActiveFilter(this.yearElem, this.year[1]);
        }

        if (this.category !== null && this.category[1] !== null && this.category[1] !== '' && this.category[1] !== undefined) {
            this.setActiveFilter(this.categoryElem, this.category[1].replace('+', ' '));
        }

        if (this.country !== null && this.country !== '' && this.country !== undefined) {
            this.setActiveFilter(this.countryElem, this.country[1]);
        }

        if (this.countryselect !== null && this.countryselect !== '' && this.countryselect !== undefined) {
            this.setActiveFilter(this.countrySelectElem, this.countryselect[1]);
        }
    }

    setActiveFilter(filterElem, elemValue) {
        let options = filterElem.querySelectorAll('option');
        options = Array.prototype.slice.call(options);

        options.forEach(option => {
            if (option.value === elemValue) {
                option.selected = true;
            }
        });

        if (elemValue) {
            this.resetFilters.classList.add('active');
        }
    }
}
